export default {
  API_URL: "https://api.alicedev.xyz",
  SOCKET_URL: "https://socket.alicedev.xyz",
  FRONT_URL: "https://alicedev.xyz",
  GOOGLE_OAUTH: "541584767290-gq5usaeqjtbr1jra4t0l9cagc57nqrvu.apps.googleusercontent.com",
  API_URL_BACKGROUND: "https://api-background.alicedev.xyz",
  SOCKET_URL_BACKGROUND: "https://socket-background.alicedev.xyz",
};

// export default {
//   API_URL: process.env.VUE_APP_API_URL,
//   SOCKET_URL: process.env.VUE_APP_SOCKET_URL,
//   FRONT_URL: process.env.VUE_APP_FRONT_URL,
//   GOOGLE_OAUTH: "5391341510-jpnimbbjsqe2l718smmtmgs86h0jcumk.apps.googleusercontent.com",
//   API_URL_BACKGROUND: process.env.VUE_APP_API_URL_BACKGROUND,
//   SOCKET_URL_BACKGROUND: process.env.VUE_APP_SOCKET_URL_BACKGROUND
// };

