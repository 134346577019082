import { api } from "../api/stagingApi.js";

export async function getAllNotifications() {
  try {
    const response = await api.get(`/notification?status=unread`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function markAsReadNotification(id) {
  try {
    const response = await api.post(`/notification/delete`, {
      id_notification: id,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function deleteNotification(id) {
  try {
    const response = await api.post(`/notification/delete`, {
      id_notification: id,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}
