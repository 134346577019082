<template>
  <div :class="activeNotification ? 'container-card active' : 'container-card'">
    <div class="card-content-title">
      <div class="content-title">
        <h4 class="font-weight-bold">Notifications</h4>
        <h4 class="text-primary font-weight-normal cursor-pointer">See all</h4>
      </div>
    </div>
    <div class="container-content">
      <div
        class="row-card"
        v-for="notif in getNotifications"
        :key="notif._id"
        @click="navigateNotification(notif.idObjective, notif.type)"
      >
        <div class="row-card-picture">
          <AliceLogoMark :width="'53px'" :height="'53px'" />
        </div>
        <div class="row-card-content">
          <p class="font-weight-bold title-text">{{ notif.title }}</p>
          <div class="msg-text-container">
            <p class="font-weight-bold msg-text">{{ notif.message }}</p>
            <button class="msg-delete" @click="$emit('markAsRead', notif._id)">
              <svg class="mark-as-read-container" viewBox="0 0 24 24">
                <path fill="#000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
              </svg>
            </button>
          </div>
          <p class="date-text">
            {{ notif.created }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../store/store';
import AliceLogoMark from './shared/AliceLogoMark.vue';
import { mapGetters, mapActions } from "vuex";

export default {
    methods: {
        ...mapActions(["setProfileImageAction"]),
        navigateNotification(id, type) {
            if (type == "objectiveUpdate") {
                this.$router.push({ name: "projectdetails", params: { id: id } });
            }
        },
    },
    computed: {
        ...mapGetters(["getNotifications"]),
        activeNotification() {
            return store.state.activeNotification;
        },
    },
    components: { AliceLogoMark }
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.container-card.active {
  display: block;
  position: absolute;
  margin-top: 1rem;
  max-width: 300px;
  /* Para centrar el card */
  background-color: white;
  border-radius: 10px;

  /* horizontal, vertical, blur, spread (dureza), color */
  box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 0.15);
  right: 1%;
  top: 85%;
}

@media screen and (min-width: 468px) {
  .container-card.active {
    top: 70%;
  }
}

/* General container of all */
.container-card {
  transition: 350ms;
  top: 90%;

  right: 1%;
  position: absolute;
  max-width: 300px;
  /* Para que no se interponga encima de otros componentes */
  display: none;
}

.container-card::before,
.container-card::after {
  content: '';
  width: 100%;
  height: 10rem;
  position: relative;
  /* background-color: green; */
}

.container-card::before {
  position: absolute;
  background-repeat: no-repeat;
  /* Centrar el svg (punta) */
  left: 28.8%;
  /* background-color: blue; */
  top: -0.5rem;
  background-position: -1cm 63%;
  width: 88px;
  height: 53px;
  /* object-fit: cover; */
}

@media screen and (min-width: 463px) {
  .container-card::before {
    left: 33.8%;
  }
}

@media screen and (min-width: 992px) {
  .container-card::before {
    left: 35%;
  }
}

@media screen and (min-width: 1200px) {
  .container-card::before {
    left: 38.8%;
  }
}

.card-content-title {
  border-bottom: 1px solid rgb(233, 229, 229);
  margin-bottom: unset;
}

h4 {
  margin-top: 0.5rem;
}

.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.container-content {
  max-height: 37vh;
  overflow-y: auto;
}

.row-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Row card separation */
  margin-bottom: 1rem;
  transition-property: background-color;
  transition-duration: 300ms;
  padding: 0.2rem 1rem;
}

.row-card:hover {
  background-color: #f0eeee;
  cursor: pointer;
}

.row-card-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  /* max-width: 262px; */
  flex: 1;
}

.title-text {
  font-size: 15px;
}

.msg-text {
  font-size: 12px;
}

.msg-text-container {
  position: relative;
}

.msg-delete:hover {
  transition: all 200ms;
  background-color: #bdbdbd;
}

.row-card-content:hover .msg-delete {
  transition: all 200ms;
  display: flex;
}.row-card-content .msg-delete {
  transition: all 200ms;
  display: none;
}
.msg-delete {
  background-color: #fff;
  transition: all 200ms;
  position: absolute;
  right: 2px;
  top: calc(50% - 12px);
  border: none;
  border-radius: 1rem;
  padding: 2px;
}

.date-text {
  font-size: 11px;
  color: gray;
}

/* Se puede usar como base para la foto circular */
.row-card-picture {
  /* background-color: red; */
  width: 40px;
  height: 40px;
  margin-right: 1.5rem;
  border-radius: 100px;
}

@media screen and (min-width: 368px) {
  .row-card-picture {
    width: 50px;
    height: 50px;
  }
}

img {
  width: 100%;
  object-fit: cover;
  background-size: cover;
  border-radius: 100px;
  height: 40px;
}

@media screen and (min-width: 368px) {
  img {
    height: 50px;
  }
}

.text-primary {
  cursor: pointer;
}


.mark-as-read-container {
  width:24px;
  height:24px
}

</style>
