<template>
  <aside id="sidebar" class="vertical-nav">
    <nav class="navbar bg-white flex-column justify-content-start">
      <router-link to="/">
        <span
          class="nav-item home-button icon-home"
          :class="{ selected: $route.name == 'home' }"
        ></span>
      </router-link>
      <router-link to="/editproject">
        <span
          class="nav-item edit-button icon-edit"
          :class="{
            selected:
              $route.name == 'editdashboard' ||
              $route.name == 'editproject',
          }"
        ></span>
      </router-link>
      <router-link to="/my-account">
        <span 
          class="nav-item settings-button icon-settings"
          :class="{ selected: $route.name == 'myaccount' }"
          ></span>
      </router-link>
    </nav>
  </aside>
</template>

<script>

export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.navbar {
  row-gap: 6rem;
  padding: 0 !important;
  margin-left: 1rem;
}
.navbar div {
  width: 60px;
  height: 50px;
  display: flex;
  row-gap: 10px;
}
.navbar div a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-item {
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  display: block;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  transition: all 0.05s;
  font-size: 35px;
  color: #767676;
  transition: all 0.1s;
  margin-right: 1rem;
}
.nav-item:hover {
  background-color: #f3f3f3;
  color: #000;
}
.nav-item:active {
  background-color: #e9e9e9;
}
.home-button {
  /*background-image: url("../../assets/Home.svg");*/
  background-size: 33px 27px;
}
.selected {
  border-right-color: #8954ba;
  transition: all 0.1s;
}
.home-button.selected {
  /*background-image: url("../../assets/Home-selected.svg");*/
  color: var(--color-purple);
}
.edit-button {
  /*background-image: url("../../assets/Edit.svg");*/
  background-size: 31px 29px;
}
.edit-button.selected {
  /*background-image: url("../../assets/Edit-selected.svg");*/
  color: var(--color-purple);
}
.project-button {
  /*background-image: url("../../assets/Dashboard.svg");*/
  background-size: 47px 40px;
}
.project-button.selected {
  /*background-image: url("../../assets/Dashboard-selected.svg");*/
  color: var(--color-purple);
}
.settings-button {
  /*background-image: url("../../assets/Settings.svg");*/
  background-size: 38px 36px;
}
</style>
