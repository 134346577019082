const defaultState = {
  loading: false,
  error: false,
  notifications: [],
};

const notificationStore = {
  state: { ...defaultState },
  mutations: {
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    restoreNotifications(state, payload) {
      state.notifications = payload.notifications;
      state.loading = payload.loading;
    },
  },
  actions: {
    setNotificationsAction({ commit }, payload) {
      commit("setNotifications", payload);
    },
    setLoadingNotificationsAction({ commit }, payload) {
      commit("setLoading", payload);
    },
    setErrorNotificationsAction({ commit }, payload) {
      commit("setError", payload);
    },
    restoreNotificationsAction({ commit }) {
      commit("restoreNotifications", defaultState);
    },
  },
  getters: {
    getNotificationsLoading: (state) => state.loading,
    getNotifications: (state) => state.notifications,
    getNotificationsNumber: (state) => {
      const unreadList = state.notifications.filter(item => item.status == "unread");
      return unreadList.length;
    },
    getNotificationsError: (state) => state.error,
  },
};

export default notificationStore;
