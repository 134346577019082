<template>
  <header class="horizontal-nav" :gutter="12">
    <nav
      class="navbar navbar-light bg-white justify-content-end fixed-top border-bottom"
    >
    <!-- <router-link :to="{ name: 'home' }"> -->
      <a href="/">

        <div class="company_logo">
          <AliceLogoMark :width="'53px'" :height="'53px'"/>
        </div>
      <!-- </router-link> -->
      </a>
      <img
        class="search-icon d-none"
        src="../../assets/Search.svg"
        alt="search-icon"
      />
      <form @submit.prevent class="form-inline" v-if="showSearchBox">
        <input
          id="navbar_search_box"
          class="form-control mr-sm-2 navbar-search-box"
          type="search"
          @input="clearField"
          @click="typing"
          @keyup="typing"
          placeholder="Search e.g. INS exam results"
          aria-label="Search"
        />
      </form>
      <div
        class="notification-icon pointer"
        @click="changeStatusNotification"
        v-click-outside="onClickOutside"
      >
        <i class="bell-icon icon-bell"></i>
        <div class="status-qty bg-danger text-light">
          {{ getNotificationsNumber }}
        </div>
        <CardNotification @markAsRead="handleMarkAsRead" />
      </div>
      <div class="profile-icon">
        <UserMenu />
      </div>
    </nav>
  </header>
</template>

<script>
import { getAllNotifications, markAsReadNotification } from "../../services/notification.js";
import AliceLogoMark from "./AliceLogoMark.vue";
import CardNotification from "../../components/CardNotification.vue";
import UserMenu from "../../components/shared/navbar/UserMenu.vue";
import { mapGetters, mapActions } from "vuex";
import { store } from "../../store/store";

export default {
  components: {
    CardNotification,
    UserMenu,
    AliceLogoMark
},
  async mounted() {
    this.getNotifications();
  },
  methods: {
    ...mapActions([
        "setNotificationsAction",
        "setLoadingNotificationsAction",
        "setErrorNotificationsAction"
    ]),
    async getNotifications() {
      try {
        if (this.getNotificationsLoading || !this.getloggedIn) {
          return;
        }
        this.setLoadingNotificationsAction(true);
        this.setErrorNotificationsAction(false);
        const res = await getAllNotifications();
        if (res && res.length > 0) {
          this.setNotificationsAction(res);
        } else {
          this.setNotificationsAction([]);
        }
      } catch (error) {
        this.setErrorNotificationsAction(true);
      } finally {
        this.setLoadingNotificationsAction(false);
      }
    },
    async handleMarkAsRead(id) {
      try {
        await markAsReadNotification(id);
      } catch (error) {
        console.log(error);
      } finally {
        await this.getNotifications();
      }
    },
    onClickOutside() {
      store.commit("setStatusOutNotification");
    },
    clearField(data) {
      if (data.target.value == "") {
        this.$root.$emit("mainSearch", data.target.value);
      }
    },
    changeStatusNotification() {
      store.commit("setStatusNotification");
    },
    typing(data) {
      this.$root.$emit("mainSearch", data.target.value);
    },
  },
  directives: {
    "click-outside": {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  computed: {
    ...mapGetters(["getNotificationsNumber", "getNotificationsLoading", "getloggedIn"]),
    showSearchBox() {
      return this.$route.name == "home";
    }
  },
};
</script>

<style scoped>
.navbar {
  padding: 0 !important;
}

.navbar-search-box {
  width: 250px !important; 
  border: none !important;
  /*display: none !important;*/
}

.navbar-search-box::placeholder {
  font-size: 15px;
  color: #858585;

}

.company_logo {
  align-self: start;
  position: fixed;
  top: 0.5rem;
  left: 1.8rem;
}

.search-icon {
  margin-right: 0.5rem;
}

.form-control {
  font-family: "Source Sans Pro", sans-serif;
}

.form-inline .form-control {
  font-size: 1.5rem;
}

.notification-icon {
  padding: 20px 20px 20px 20px !important;
  border-left: 1px solid var(--color-light-grey);
  cursor: pointer;
}

.notification-icon:hover {
  background-color: #f5f5f5;
}
.notification-icon:active {
  background-color: #eeeeee;
}

.bell-icon {
  font-size: 24px;
  cursor: pointer;
}

/* Quantity notifications */
.status-qty {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 7px;
}

.profile-icon {
  padding: 0;
  margin-right: 10px;
  border-left: 1px solid var(--color-light-grey);
}

.profile-picture {
  background-color: #000;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  border-radius: 50%;
}
</style>
