import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import sessionStore from "./sessionStore.js";
import notificationStore from "./notificationStore.js";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    sessionStore,
    notificationStore,
  },
  state: {
    loggedIn: false,
    loginUserId: "",
    projectInId: "",
    loginUserFullName: "",
    objectives: [""],
    companyName: "",
    projectName: "",
    filelist: [],
    demoView: false,
    profileImg: null,
    initials: "",
    permissionsArray: [],
    values: {},
    activeNotification: false,
    dashboardFirstLevel: {},
    dataDemopulico: {},
    dashboardByStatusData: {},
    projectHeaders: [],
    optionColumnSelected: null,
    cached: false,
  },
  plugins: [createPersistedState({
    reducer: (state) => ({
      // Aquí se excluye la propiedad "cached"
      optionColumnSelected: state.optionColumnSelected,
      projectHeaders: state.projectHeaders,
      dashboardByStatusData: state.dashboardByStatusData,
      dataDemopulico: state.dataDemopulico,
      dashboardFirstLevel: state.dashboardFirstLevel,
      activeNotification: state.activeNotification,
      values: state.values,
      permissionsArray: state.permissionsArray,
      initials: state.initials,
      profileImg: state.profileImg,
      demoView: state.demoView,
      filelist: state.filelist,
      projectName: state.projectName,
      companyName: state.companyName,
      objectives: state.objectives,
      loginUserFullName: state.loginUserFullName,
      projectInId: state.projectInId,
      loginUserId: state.loginUserId,
      loggedIn: state.loggedIn,
    }),
  })],
  mutations: {
    setUserId(state, id) {
      state.userId = id;
    },

    setStatusNotification(state) {
      if (state.activeNotification == true) {
        state.activeNotification = false;
      } else {
        state.activeNotification = true;
      }
    },

    setStatusOutNotification(state) {
      state.activeNotification = false;
    },

    setDemoView(state, demoView) {
      state.demoView = demoView;
    },
  },
});
