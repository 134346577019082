<template>
  <div
    class="dropdownUserMenu"
    :class="{ active: showActions }"
    v-click-outside="closeActions"
  >
    <button class="dropbtnUserMenu" @click="toggleActions">
      {{ getUserInitials }}
    </button>
    <div class="dropdownUserMenu-content">
      <!-- <button @click="handleProfile">Profile</button> -->
      <button @click="handleMyAccount">My Account</button>
      <button @click="handleLogout">Logout</button>
    </div>
  </div>
</template>

<script>
import clickOutsideDirective from "../../../utils/clickOutsideDirective.js";
import router from "../../../router";
import { socket_main } from "../../../services/socket.js";
import { socket_background } from "../../../services/socket_background.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserMenu",
  directives: {
    "click-outside": clickOutsideDirective,
  },
  props: {},
  data() {
    return {
      showActions: false,
    };
  },
  methods: {
    ...mapActions(["logoutSession"]),
    handleProfile() {
      try {
        if (this.$route.path !== "/editprofile") {
          router.push("/editprofile");
        }
        this.closeActions();
      } catch (error) {
        console.log("error on redirec to profile");
      }
    },
    handleMyAccount() {
      try {
        if (this.$route.path !== "/my-account") {
          router.push({ name: 'myaccount' });
        }
        this.closeActions();
      } catch (error) {
        console.log("error on redirec to myaccount");
      }
    },
    handleLogout() {
      try {
        this.closeActions();
        this.logoutSession();
        router.push("/login");
        socket_main.disconnect();
        socket_background.disconnect();
      } catch (error) {
        router.push("/login");
      }
    },
    toggleActions() {
      this.showActions = this.showActions === true ? false : true;
    },
    closeActions() {
      this.showActions = false;
    },
  },
  computed: {
    ...mapGetters(["getUserInitials"]),
  },
};
</script>

<style scoped>
@import "../../../assets/styles/components/shared/navbar/UserMenu.css";
</style>
