import Vue from "vue";
import Router from "vue-router";
import { store } from "./store/store";
import Vuex from "vuex";

Vue.use(Router);
Vue.use(Vuex);

const router = new Router({
  base: "/app/",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/sign-up",
      name: "sign-up",
      component: () => import("./views/SignUp.vue"),
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      }
    },
    {
      path: "/forgotten-password",
      name: "forgotten-password",
      component: () => import("./views/password/ForgottenPassword.vue"),
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      }
    },
    {
      path: "/restore/",
      name: "restore",
      component: () => import("./views/password/SetNewPassword.vue"),
      props: (route) => ({
        token: route.query.token,
        email: route.query.email,
      }),
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      }
    },
    {
      path: "/createpass/:token",
      name: "createpass",
      component: () => import("./views/CreatePassword.vue"),
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      }
    },
    {
      path: "/projectdetails/:id",
      name: "projectdetails",
      component: () => import("./views/Projectdetails.vue"),
    },
    {
      path: "/ict",
      name: "ict",
      component: () => import("./views/Ict.vue"),
    },
    {
      path: "/framework",
      name: "framework",
      component: () => import("./views/Framework.vue"),
    },
    {
      path: "/frameworktem",
      name: "frameworktem",
      component: () => import("./views/FrameworkTem.vue"),
    },
    {
      path: "/editproject",
      name: "editproject",
      component: () => import("./views/EditProject.vue"),
    },
    {
      path: "/editbenchmetric/:id?",
      name: "editbenchmetric",
      component: () => import("./views/EditBenchMetric.vue"),
      props: route => ({ query: route.query })
    },
    {
      path: "/404",
      name: "four-o-four",
      component: () => import("./views/404.vue"),
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      }
    },
    {
      path: "/useradministration",
      name: "useradministration",
      component: () => import("./views/UserAdministration.vue"),
    },
    {
      path: "/editprofile",
      name: "editprofile",
      component: () => import("./views/EditProfile.vue"),
    },
    {
      path: "/my-account",
      name: "myaccount",
      component: () => import("./modules/users/pages/MyAccount.vue"),
    },
    {
      path: "/attendance",
      name: "attendance",
      component: () => import("./modules/pages/Attendance.vue"),
    },
    {
      path: "/ins",
      name: "ins",
      component: () => import("./modules/pages/Ins.vue"),
    },
    {
      path: "/subscriptionSuccess/:sessionId",
      name: "subscriptionSucess",
      component: () => import("./views/SubscriptionSuccess.vue"),
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      }
    },
    {
      path: "/verify",
      component: () => import("./views/VerifyEmailPage.vue"),
      name: "verify",
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      },
     },
    {
      path: "/invitation",
      component: () => import("./views/InvitationStatusChange.vue"),
      name: "invitation",
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      }, 
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!store.state.sessionStore.email) {
    store.dispatch("loadUserSession");
  }
  if (store.state.sessionStore.loggedIn === false && to.name === 'sign-up') {
    return next(); 
  }

  if (store.state.sessionStore.loggedIn === false && to.name !== "login") {
    if (to.name === "invitation" || to.name === "verify" || to.name === "restore" || to.name === "createpass" || to.name === "forgotten-password") {
      next();
    } else {
      next("/login");
    }
    return;
  } else if (store.state.sessionStore.loggedIn === true) {
    if (to.name === "login" || to.name === "verify") {
      if (to.query.signup == true) {
        next("/signup?signup=true");
        return;
      } else {
        next("/");
        return;
      }
    } else if (to.name === "verify" || to.name === "createpass") {
      store.dispatch("logoutSession");
      return next();
    } else if (to.name === "restore" || to.name === "forgotten-password") {
      next("/");
      return;
    }
  }

  /*if (store.state.sessionStore.loggedIn === true && to.name != "subscriptionSucess") {
    const permissions = store.state.sessionStore.permissions.filter(
      (permissions) => permissions == to.name
    );
    if (!permissions.length) {
      next("/404");
      return;
    }
  }*/
  next();
});

export default router;
