<template>
  <div id="app">
    <b-container fluid>
      <Sidebar v-if="$route.meta.hideSidebar !== true" />
      <main>
        <div id="nav">
          <Navbar v-if="$route.meta.hideNavbar !== true" />
        </div>
        <div class="m-0">
          <router-view />
        </div>
      </main>
    </b-container>
  </div>
</template>

<script>
import { socket_main, socketStateMain } from "./services/socket.js";
import { socket_background, socketStateBackground } from "./services/socket_background.js";
import Navbar from "@/components/shared/Navbar.vue";
import Sidebar from "@/components/shared/Sidebar.vue";
//import { store } from './store/store'
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Sidebar,
    Navbar,
  },
  metaInfo: {
    title: "Alice",
  },
  data() {
    return {
      intervalId: null,
    };
  },
  computed: {
    ...mapGetters(['getloggedIn']),
  },
  watch: {
    getloggedIn(newVal) {
      this.initSockets(newVal);
    },
  },
  mounted() {
    this.initSockets(this.getloggedIn);
  },
  created() {
    const loggedIn = this.getloggedIn;
    if (loggedIn) {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        //reinitializeSocketWithToken(storedToken);
      }
    }
    this.intervalId = setInterval(() => {this.initSockets(this.getloggedIn)}, 6000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    initSockets(logged) {
      try {
        if (logged === true) {
          if (socketStateBackground.connected !== true) {
            console.log("Socket background WITH SESSION");
            socket_background.connect();
          }
          if (socketStateMain.connected !== true) {
            console.log("Socket main WITH SESSION");
            socket_main.connect();
          }
        } else {
          if (socket_background.connected === true) {
            console.log("Socket background NO SESSION");
            socket_background.disconnect();
          }
          if (socketStateMain.connected === true) {
            console.log("Socket main NO SESSION");
            socket_main.disconnect();
          }
        }
      } catch (error) {
        console.log("Socket Error connection", error);
      }
    },
  },
};
</script>

<style src="./assets/styles/app.css"></style>
