import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import vSelect from 'vue-select'
import VueCookies from 'vue-cookies';

import 'vue-select/dist/vue-select.css'
import BootstartpVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/styles/skeleton.css'
import '../public/fonts/alice/css/alice.css'
import { Row, Column } from 'vue-grid-responsive'
import { BootstrapVueIcons } from 'bootstrap-vue'
import { BCarousel } from 'bootstrap-vue'

require('dotenv').config()

Vue.component('v-select', vSelect)
Vue.use(BootstartpVue)
Vue.component('row', Row)
Vue.component('column', Column)
Vue.component('b-carousel', BCarousel)

Vue.use(BootstrapVueIcons)
Vue.use(VueCookies, { expires: '1d' })


Vue.config.productionTip = false

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
