import Vue from 'vue';

const defaultState = {
  loggedIn: false,
  email: "",
  name: "",
  id_group: "",
  initials: "",
  image: "",
  permissions: [],
}

const sessionStore = {
  state: {...defaultState},
  mutations: {
    setUserSession(state, payload) {
      state.loggedIn = payload.loggedIn;
      state.email = payload.email;
      state.name = payload.name;
      state.id_group = payload.id_group;
      state.initials = payload.initials;
      state.image = payload.image;
      state.permissions = payload.permissions;
    },
    setProfileImage(state, payload) {
      state.image = payload.image;
    },
    clearCookies() {
      Vue.$cookies.remove("lastSelectedOptionCompany");
      Vue.$cookies.remove("lastSelectedOptionProject");
      Vue.$cookies.remove("lastSelectedOptionCompanyHome");
      Vue.$cookies.remove("lastSelectedOptionProjectHome");
      Vue.$cookies.remove("isTermsAccepted");
    },
  },
  actions: {
    setUserSessionAction({ commit }, payload) {
      commit("setUserSession", payload);
    },
    setProfileImageAction({ commit }, payload) {
      commit("setProfileImage", payload);
    },
    loadUserSession({ commit }) {
      const userSession = JSON.parse(localStorage.getItem('user_data'));
      if (userSession) {
        commit('setUserSession', userSession);
      }
    },
    logoutSession({ commit, dispatch }) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_data");
      dispatch('clearCookies');
      commit('setUserSession', defaultState);
    },
    clearCookies({ commit }) {
      commit('clearCookies');
    }
  },
  getters: {
    getloggedIn: (state) => state.loggedIn,
    getUserEmail: (state) => state.email,
    getUserInitials: (state) => state.initials,
    getUserImage: (state) => state.image,
    getPermissions: (state) => state.permissions,
  },
};

export default sessionStore;
