import Vue from "vue";
import config from "../config.js"
import { io } from "socket.io-client";

export const socketStateMain = new Vue({
  data() {
    return {
      connected: false,
      changeUserRole: [],
      changeUserJoinStatus: [],
      token: "",
    };
  },
});

let socket_main;

function initializeSocket() {
  const URL = `${config.SOCKET_URL}`;
  const tkn = `${localStorage.getItem("token")}`;
  socket_main = io(URL, {
    reconnectionDelayMax: 1000,
    auth: {
      token: socketStateMain.token || tkn,
    },
    retries: 5,
    autoConnect: false,
    transports: ["polling"],
  });

  socket_main.on("connect", () => {
    socketStateMain.connected = true;
    console.log(`Connected main socket...`);
  });

  socket_main.on("connect_error", (error) => {
    console.log("connect_error", error);
  });

  socket_main.on("ping", () => {
    console.log("ping");
  });

  socket_main.on("disconnect", (reason) => {
    socketStateMain.connected = false;
    if (reason === "io server disconnect") {
      setTimeout(() => {
        console.log("SOCKET MAIN ERROR DE TRANSPORTE: REINTENTANDO");
        socket_main.connect();
      }, 2000);
    } else if (reason === "transport error") {
      setTimeout(() => {
        console.log("SOCKET MAIN ERROR DE TRANSPORTE: REINTENTANDO");
        socket_main.connect();
      }, 2000);
    }
    console.log(`Disconnecting main socket...`);
  });

  socket_main.on("user-role-changed", (args) => {
    socketStateMain.changeUserRole.push(args);
  });

  socket_main.on("change-user-join-status", (args) => {
    socketStateMain.changeUserJoinStatus.push(args);
  });
}

// Llama a la función de inicialización cuando se carga la página
initializeSocket();

// Exporta socket_main
export { socket_main };

// Crea una función para reinicializar socket_main con un nuevo token
export function reinitializeSocketWithToken(newToken) {
  socket_main.disconnect(); // Desconecta el socket
  socketStateMain.token = newToken; // Actualiza el token
  console.log("Connecting socket...");
  initializeSocket(); // Inicializa el socket nuevamente con el nuevo token
}
